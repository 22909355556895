import React, { Fragment } from "react";
import { Box } from "theme-ui";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import SEO from "./SEO";
import Layout from "./Layout";

const Page = ({ blok }) => {
	if (!blok) {
		return null;
	}

	const { title, description, main_content, main_content_alignment } =
		blok || {};

	return (
		<Fragment>
			<SEO description={description} title={title} />
			<Layout>
				<Box
					sx={{
						textAlign: main_content_alignment,
						"*:first-of-type": {
							mt: 0,
						},
					}}
				>
					<ReactMarkdown source={main_content} />
				</Box>
			</Layout>
		</Fragment>
	);
};

Page.defaultProps = {
	blok: null,
};

Page.propTypes = {
	blok: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string,
		component: PropTypes.string,
	}),
};

export default Page;
