import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";

class StoryblokEntry extends React.Component {
	static getDerivedStateFromProps(props, state) {
		if (state.story.uuid === props.pageContext.story.uuid) {
			return null;
		}

		return StoryblokEntry.prepareStory(props);
	}

	static prepareStory(props) {
		const story = { ...props.pageContext.story };
		story.content = JSON.parse(story.content);

		return { story };
	}

	constructor(props) {
		super(props);

		this.state = StoryblokEntry.prepareStory(props);
	}

	render() {
		const { story = {} } = this.state;
		const { content } = story;

		const { _uid: uid } = content;

		return (
			<div>
				<Page key={uid} blok={content} />
			</div>
		);
	}
}

StoryblokEntry.propTypes = {
	pageContext: PropTypes.shape({
		story: PropTypes.shape({
			uuid: PropTypes.string,
		}),
	}).isRequired,
};

export default StoryblokEntry;
